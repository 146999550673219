import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import About from "./pages/About";
import Services from "./pages/Services";
import Products from "./pages/Products";
import Blogs from "./pages/Blogs";
import Contact from "./pages/Contact";
import Blog1 from "./blogs/blog1";
import Websoln from "./pages/web_soln";
import Digimrkt from "./pages/Digi_mrkt";
import Leasing from "./pages/Leasing";
import Terms from "./pages/TermsCondition";
import Privacy from "./pages/PrivacyPolicy";
import Printers from "./products/Printer";
import ProductDetail from "./products/ProductDetail";
import { products } from "./products/productData";
import Consumables from "./products/Consumables";
import ConsumableDetail from "./products/ConsumableDetail";
import { consumables } from "./products/ConsumableData";
import SearchEngine from "./pages/SearchEngine";
import SocialMedia from "./pages/SocialMedia";
import Ppc from "./pages/Ppc";
import Contentmrkt from "./pages/Content_mrkt";
import Portfolio from "./pages/Portfolio";
import DigiMrktAgency from "./pages/Digi_mrkt_agency";
import PrinterRental from "./pages/PrinterRental";
import Home2 from "./pages/Home2";
import ItService from "./pages/ItService";
import Cctv from "./pages/Cctv";
import RentalService from "./pages/RentalService";
import Telecom from "./pages/Telecom";
import Amc from "./pages/Amc";
import CloudComputing from "./pages/CloudComputing";
import NetworkInfrstr from "./pages/NetworkInfrstr";
import ItConsulting from "./pages/ItConsulting";
import CyberSecurity from "./pages/CyberSecurity";
import DataManagement from "./pages/DataManagement";
import SoftwareDev from "./pages/SoftwareDev";
import BusinessIntlgnc from "./pages/BusinessIntlgnc";
import WebsiteAgency from "./pages/WebsiteAgency";
import Sitemap from "./pages/Sitemap";
import NetworkSolutions from "./pages/NetworkSolutions";
import PhotocopierPrinterRental from "./pages/PhotocopierPrinterRental";
import Offer from "./pages/Offer";
import Branding from "./pages/Branding";
import WebDev from "./pages/WebDev";
import AppDev from "./pages/AppDev";
import Certificate from "./pages/Certificate";
import Blog from "./pages/Blog"; // Create a Blog component
import BlogPost from "./pages/BlogPost"; // Individual blog post component
import useFetch from "./hooks/useFetch";
import MccCCTv from "./pages/MccCCTv";
import Whatsapp from "./components/Whatsapp";

function App() {
  const {loading,error,data}= useFetch("https://supastrapi.onrender.com/api/blogs?populate=*")
  // if (loading) {
  //   return (
  //     <div className="loading  d-flex justify-content-center align-items-center mt-5">
  //       <BounceLoader color={"#f15c34"} loading={loading} size={100}  />
  //     </div>
  //   );
  // }
  if (error) return console.log(error);

  console.log(data);

//   const dummyPosts = {
//     data: [
//       {
//         blogId: 1,
//         blogTitle: "HOW TO ENHANCE YOUR BUSINESS THROUGH SEO",
//         publishedDate: "2024-01-10",
//         summary:
//           "SEO plays an important role in establishing a strong online presence and attracting valuable leads, so looking at how artificial intelligence can be leveraged for businesses in Dubai to succeed is important.                                                                                                                                            You must know how to make your business stand out in the busy Dubai market and use SEO to succeed.",
//         content: `<h2 style="color: #2a9d8f; font-size: 24px; font-weight: bold;  margin-bottom: 20px;">How to Conduct Effective Keyword Research in a Competitive Environment</h2> 
// <p style="color: #264653; font-size: 16px; line-height: 1.5; ">We provide you with a structured guide to creating an effective keyword research strategy for SEO in a competitive environment.</p> 
// <h3 style="color: #e76f51; font-size: 20px; font-weight: bold; ">Keyword Research Strategy</h3> 
// <p style="color: #264653; font-size: 16px; line-height: 1.5; ">Keyword research is the process of increasing website traffic, improving ranking, or creating leads.</p> 
// <h4 style="color: #f4a261; font-size: 18px; font-weight: bold;">1. Understand Your Audience</h4> 
// <p style="color: #264653; font-size: 16px; line-height: 1.5; ">Use tools such as Google Analytics and social media insights to collect data on your audience's interests and behaviors.</p> 
// <h4 style="color: #f4a261; font-size: 18px; font-weight: bold;">2. Use Keyword Research Tools</h4> 
// <ul style="color: #264653; font-size: 16px; line-height: 1.5; list-style-type: disc; padding-left: 20px;">
//   <li><strong>Google Keyword Planner</strong>: Get keyword ideas and search volumes.</li> 
//   <li><strong>SEMrush/Ahrefs</strong>: Analyze competitors and find related keywords.</li> 
//   <li><strong>Ubersuggest</strong>: Find long-tail keywords and their measurements.</li> 
// </ul> 
// <h4 style="color: #f4a261; font-size: 18px; font-weight: bold;">3. Competitor Analysis</h4> 
// <p style="color: #264653; font-size: 16px; line-height: 1.5; ">Identify top competitors, use tools to see which keywords they rank for, analyze their content, and look for opportunities where you can outdo them.</p> 
// <h4 style="color: #f4a261; font-size: 18px; font-weight: bold;">4. Focus on Long-Tail Keywords</h4> 
// <p style="color: #264653; font-size: 16px; line-height: 1.5; ">Specific and less competitive: These keywords often have low search volumes but have a high conversion potential.</p> 
// <h4 style="color: #f4a261; font-size: 18px; font-weight: bold;">5. Observe and Configure</h4> 
// <p style="color: #264653; font-size: 16px; line-height: 1.5; ">Track performance: Use the Google search console and analytics tools to monitor keyword performance. Set the strategy: Be flexible and modify your approach based on what works.</p> 
// <h4 style="color: #f4a261; font-size: 18px; font-weight: bold;">6. Align Content with Keywords</h4> 
// <p style="color: #264653; font-size: 16px; line-height: 1.5; ">Optimize existing content: Update titles, meta-descriptions, and body content to include targeted keywords. Create new content: Develop blog posts, guides, or landing pages aimed at keywords in your order of priority.</p> 
// <h3 style="color: #e76f51; font-size: 20px; font-weight: bold; ">Conclusion</h3> 
// <p style="color: #264653; font-size: 16px; line-height: 1.5; ">By systematically following these steps, you will be able to conduct effective keyword research that will increase your SEO efforts and help you stand out in a competitive marketplace. Regularly revising and updating your keyword strategy will ensure continued success.</p> 
// <h3 style="color: #e76f51; font-size: 20px; font-weight: bold; ">Keyword Research Benefits</h3> 
// <p style="color: #264653; font-size: 16px; line-height: 1.5; ">Ultimately, keyword research helps you understand exactly what content to create to best help your business goals and target relevant traffic.</p> 
// <p style="color: #264653; font-size: 16px; line-height: 1.5; ">We've hired the best content writers in our company. We are capable of bringing your business in Dubai to the threshold of rise, and for that, each of our workers is working very precisely. We strive to be the best digital agency in Dubai and take your business to a higher level.</p>
// `,
//         coverImage: {
//           url: "https://images.inc.com/uploaded_files/image/1920x1080/getty_464186219_93432.jpg", // Replace with your image URL
//         },
//         author: "John Doe",
//         slug: "boost-your-digital-marketing-2024",
//       },
 
//     ],
//   };

  return (
    <>
      <Whatsapp />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home2 />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/products" element={<Products />} />
          <Route path="/Portfolio" element={<Portfolio />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/website-solution" element={<Websoln />} />
          <Route path="/it-service" element={<ItService />} />
          <Route path="/cctv" element={<Cctv />} />
          <Route path="/branding" element={<Branding />} />
          <Route path="/website-development" element={<WebDev />} />
          <Route path="/app-development" element={<AppDev />} />
          <Route path="/rental-service" element={<RentalService />} />
          <Route path="/digital-marketing" element={<Digimrkt />} />
          <Route path="/telecom-solutions" element={<Telecom />} />
          <Route path="/network-solutions" element={<NetworkSolutions />} />
          <Route path="/amc" element={<Amc />} />
          <Route path="/cloud-computing" element={<CloudComputing />} />
          <Route path="/cybersecurity" element={<CyberSecurity />} />
          <Route path="/network-infrastructure" element={<NetworkInfrstr />} />
          <Route path="/data-management" element={<DataManagement />} />
          <Route path="/software-development" element={<SoftwareDev />} />
          <Route path="/it-consulting" element={<ItConsulting />} />
          <Route path="/business-intelligence" element={<BusinessIntlgnc />} />
          <Route
            path="/digital-marketing-agency-dubai"
            element={<DigiMrktAgency />}
          />
          <Route
            path="/photocopier-printer-rental-dubai"
            element={<PhotocopierPrinterRental />}
          />
          <Route path="/website-agency-uae" element={<WebsiteAgency />} />
          <Route
            path="/digital-marketing/search-engine"
            element={<SearchEngine />}
          />
          <Route
            path="/digital-marketing/social-media"
            element={<SocialMedia />}
          />
          <Route path="/digital-marketing/ppc" element={<Ppc />} />
          <Route
            path="/digital-marketing/content-marketing"
            element={<Contentmrkt />}
          />
          <Route path="/leasing" element={<Leasing />} />
          <Route path="/terms-and-conditions" element={<Terms />} />
          <Route path="/privacy-and-policy" element={<Privacy />} />
          <Route path="/printer" element={<Printers />} />
          <Route path="/rental" element={<Printers />} />
          <Route path="/printer-rental" element={<PrinterRental />} />
          <Route path="/product/printer" element={<Printers />} />
          <Route path="/offer" element={<Offer />} />
          <Route path="/consumables" element={<Consumables />} />
          <Route path="/Certificate" element={<Certificate />} />
          <Route
            path="/maximizing-your-social-media-presence-tips-from-a-top-uae-agency"
            element={<Blog1 />}
          />
          <Route
            path="/product/:model"
            element={<ProductDetail products={products} />}
          />
          <Route
            path="/consumables/:consumableName"
            element={<ConsumableDetail consumables={consumables} />}
          />
          <Route path="/sitemap" element={<Sitemap />} />
          <Route path="/blog" exact element={<Blog posts={data} loading={loading} />} />
          <Route
            path="/blog/post/:id"
            element={<BlogPost posts={data} loading={loading} />}
          />
          <Route path="mcc-certified-cctv-solutions" element={<MccCCTv />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

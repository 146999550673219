import React from "react";
import { useParams } from "react-router-dom";
import Whatsapp from "../components/Whatsapp";
import { Helmet } from "react-helmet";
import Navbar2 from "../components/Navbar2";
import Footer from "../components/Footer";
import { marked } from "marked";
import { BounceLoader } from "react-spinners";

const BlogPost = ({ posts,loading }) => {
  const params = useParams();
  console.log("params: ", params.id);


  if (loading) {
    return (
      <div className="loading  d-flex justify-content-center align-items-center mt-5">
        <BounceLoader color={"#f15c34"} loading={loading} size={100} />
      </div>
    );
  }

  // Check if posts and posts.data exist before using them
  const post = posts?.data?.find(
    (p) => p.attributes.blogId === Number(params.id)
  );

  console.log("post: ", post);

  // Check if post and post.attributes.summary exist before converting to HTML
  const htmlContent = post?.attributes?.summary
    ? marked(post.attributes.summary)
    : "";

  return (
    <>
      <Whatsapp />
      <Helmet>
        <title>{post ? post.attributes.blogTitle : "Blog Post"}</title>
        <meta
          name="description"
          content={post ? post.attributes.blogTitle : "Blog Post"}
        />
        <link rel="canonical" href={window.location.href}></link>
      </Helmet>
      <Navbar2 />

      <div className="blog-post">
        {post ? (
          <div className="blog-post-Details">
            {/* Ensure the coverImage path is correct */}
            {post?.attributes?.coverImage?.data && (
              <img
                src={post?.attributes?.coverImage?.data[0]?.attributes?.url}
                width="100%"
                height={400}
                alt={post?.attributes?.blogTitle}
              />
            )}
            <h1 className="title-blue">{post.attributes.blogTitle}</h1>
            <p>{post.attributes.publishedDate || "Unknown Date"}</p>
            <p
              className="blog-summary mt-3"
              dangerouslySetInnerHTML={{ __html: htmlContent }}
            />
          </div>
        ) : (
          <p>Post not found</p>
        )}
      </div>

      <section className="footer-section">
        <Footer />
      </section>
    </>
  );
};

export default BlogPost;

// ------------------------ CONSUMABLES -------------------------------

import {ConsumablesImg} from '../components/ConsumableImage';

export const consumables = [
    {consumableName: 'DX-C20TC', brand: 'Sharp', lifeYield: '6k', TonerPartName: 'DX-C200', colour: 'Cyan', image: ConsumablesImg.DXC20TY},
    {consumableName: 'DX-C20TM', brand: 'Sharp', lifeYield: '6k', TonerPartName: 'DX-C200', colour: 'Magenta', image: ConsumablesImg.DXC20TM},
    {consumableName: 'DX-C20TC', brand: 'Sharp', lifeYield: '6k', TonerPartName: 'DX-C200', colour: 'Cyan', image: ConsumablesImg.DXC20TY},
    {consumableName: 'DX-C20TB', brand: 'Sharp', lifeYield: '6k', TonerPartName: 'DX-C200', colour: 'Black', image: ConsumablesImg.DXC20TB},
    {consumableName: 'MX-C38FTM', brand: 'Sharp', lifeYield: '10k', TonerPartName: 'MX-C310/MX-C311', colour: 'Magenta', image: ConsumablesImg.MXC38FTM},
    {consumableName: 'MX-C38FTC', brand: 'Sharp', lifeYield: '10k', TonerPartName: 'MX-C310/MX-C311', colour: 'Cyan', image: ConsumablesImg.MXC38FTC},
    {consumableName: 'MX-C38FTB', brand: 'Sharp', lifeYield: '10k', TonerPartName: 'MX-C310/MX-C311', colour: 'Black', image: ConsumablesImg.MXC38FTB},
    {consumableName: 'MX-70FTYA', brand: 'Sharp', lifeYield: '32k', TonerPartName: 'MX-5500 /MX-6200 /MX-7000', colour: 'Black', image: ConsumablesImg.MX70FTYA},
    {consumableName: 'MX-70FTCA', brand: 'Sharp', lifeYield: '32k', TonerPartName: 'MX-5500 /MX-6200 /MX-7000', colour: 'Cyan', image: ConsumablesImg.MX70FTCA},
    {consumableName: 'MX-51FTBA', brand: 'Sharp', lifeYield: '40k', TonerPartName: 'MX-4110/MX-5110/MX-4140', colour: 'Black', image: ConsumablesImg.MX51FTBA},
    {consumableName: 'MX-31FTYA', brand: 'Sharp', lifeYield: '15k', TonerPartName: 'MX-4100 / MX-5000', colour: 'Yellow', image: ConsumablesImg.MX31FTYA},
    {consumableName: 'MX-31FTMA', brand: 'Sharp', lifeYield: '15k', TonerPartName: 'MX-2600/MX-3100/MX-2301', colour: 'Magenta', image: ConsumablesImg.MX31FTMA},
    {consumableName: 'MX-31FTCA', brand: 'Sharp', lifeYield: '15k', TonerPartName: 'MX-4100 / MX-5000', colour: 'Cyan', image: ConsumablesImg.MX31FTCA},
    {consumableName: 'MX-50FTBA', brand: 'Sharp', lifeYield: '36K', TonerPartName: 'MX-4100 / MX-5000', colour: 'Black', image: ConsumablesImg.MX50FTBA},
    {consumableName: 'MX-36FTYA', brand: 'Sharp', lifeYield: '15k', TonerPartName: 'MX-2610/MX-3110/MX-3610', colour: 'Yellow', image: ConsumablesImg.MX36FTYA},
    {consumableName: 'MX-36FTMA', brand: 'Sharp', lifeYield: '15k', TonerPartName: 'MX-2610/MX-3110/MX-3610', colour: 'Magenta', image: ConsumablesImg.MX36FTMA},
    {consumableName: 'MX-36FTCA', brand: 'Sharp', lifeYield: '15k', TonerPartName: 'MX-2610/MX-3110/MX-3610', colour: 'Cyan', image: ConsumablesImg.MX36FTCA},
    {consumableName: 'MX-36FTBA', brand: 'Sharp', lifeYield: '24k', TonerPartName: 'MX-2610/MX-3110/MX-3610', colour: 'Black', image: ConsumablesImg.MX36FTBA},
    {consumableName: 'MX-31FTBA', brand: 'Sharp', lifeYield: '18k', TonerPartName: 'MX-2600/MX-3100/MX-2301', colour: 'Black', image: ConsumablesImg.MX31FTBA},
    {consumableName: 'MX-27FTYA', brand: 'Sharp', lifeYield: '15k', TonerPartName: 'SHARP MX-2700/MX-2300/MX-3500/MX-4500', colour: 'Yellow', image: ConsumablesImg.MX27FTYA},
    {consumableName: 'MX-27FTMA', brand: 'Sharp', lifeYield: '15k', TonerPartName: 'SHARP MX-2700/MX-2300/MX-3500/MX-4500', colour: 'Magenta', image: ConsumablesImg.MX27FTMA},
    {consumableName: 'MX-27FTCA', brand: 'Sharp', lifeYield: '15k', TonerPartName: 'SHARP MX-2700/MX-2300/MX-3500/MX-4500', colour: 'Magenta', image: ConsumablesImg.MX27FTCA},
    {consumableName: 'MX-27FTBA', brand: 'Sharp', lifeYield: '18K', TonerPartName: 'SHARP MX-2700/MX-2300/MX-3500/MX-4500', colour: 'Black', image: ConsumablesImg.MX27FTBA},
    {consumableName: 'MX-23FTYA', brand: 'Sharp', lifeYield: '10K', TonerPartName: 'MX-1810/MX-2010U/MX-3114 /MX-2614/MX2314/MX-2310/MX-3111', colour: 'Black', image: ConsumablesImg.MX27FTBA},
    {consumableName: 'MX-23FTYA', brand: 'Sharp', lifeYield: '10K', TonerPartName: 'MX-1810/MX-2010U/MX-3114 /MX-2614/MX2314/MX-2310/MX-3111', colour: 'Black', image: ConsumablesImg.MX23FTYA},
    {consumableName: 'MX-23FTMA', brand: 'Sharp', lifeYield: '10K', TonerPartName: 'MX-1810/MX-2010U/MX-3114 /MX-2614/MX2314/MX-2310/MX-3111', colour: 'Black', image: ConsumablesImg.MX23FTMA},
    {consumableName: 'MX-23FTCA', brand: 'Sharp', lifeYield: '10K', TonerPartName: 'MX-1810/MX-2010U/MX-3114 /MX-2614/MX2314/MX-2310/MX-3111', colour: 'Black', image: ConsumablesImg.MX23FTCA},
    {consumableName: 'MX-23FTBA', brand: 'Sharp', lifeYield: '18K', TonerPartName: 'MX-1810/MX-2010U/MX-3114 /MX-2614/MX2314/MX-2310/MX-3111', colour: 'Black', image: ConsumablesImg.MX23FTBA},
    {consumableName: 'MX-850FT', brand: 'Sharp', lifeYield: '120K', TonerPartName: 'MX-M1100/MX-M950', colour: 'Black', image: ConsumablesImg.MX850FT},
    {consumableName: 'MX-754FT', brand: 'Sharp', lifeYield: '83K', TonerPartName: 'MX-M654/MX-M754', colour: 'Black', image: ConsumablesImg.MX754FT},
    {consumableName: 'MX-753FT', brand: 'Sharp', lifeYield: '83K', TonerPartName: 'MX-M623/MX-M753', colour: 'Black', image: ConsumablesImg.MX754FT},
    {consumableName: 'MX-753FT', brand: 'Sharp', lifeYield: '83K', TonerPartName: 'MX-M623/MX-M753', colour: 'Black', image: ConsumablesImg.MX753FT},
    {consumableName: 'MX-560FT', brand: 'Sharp', lifeYield: '40K', TonerPartName: 'MX-M464/MX-M564', colour: 'Black', image: ConsumablesImg.MX560FT},
    {consumableName: 'MX-500FT', brand: 'Sharp', lifeYield: '40K', TonerPartName: 'MX-M363/MX-M453/MX-M503/MX-M283/MXM282/MX-M362/MX-M452/MXM502', colour: 'Black', image: ConsumablesImg.MX500FT},
    {consumableName: 'MX-315FT', brand: 'Sharp', lifeYield: '27K', TonerPartName: 'MX-M315/MX-M265', colour: 'Black', image: ConsumablesImg.MX315FT},
    {consumableName: 'MX-312FT', brand: 'Sharp', lifeYield: '25K', TonerPartName: 'MX-M264/MX-M314/MX-M354/AR-5726/MXM260N/MX-M310', colour: 'Black', image: ConsumablesImg.MX312FT},
    {consumableName: 'MX-238FT', brand: 'Sharp', lifeYield: '8K', TonerPartName: 'AR-6020/AR-6023', colour: 'Black', image: ConsumablesImg.MX238FT},
    {consumableName: 'MX-237FT', brand: 'Sharp', lifeYield: '22K', TonerPartName: 'AR-6020/AR-6023/AR-7420', colour: 'Black', image: ConsumablesImg.MX237FT},
    {consumableName: 'MX-236FT', brand: 'Sharp', lifeYield: '10K', TonerPartName: 'AR-5618/AR-5620/AR-5623', colour: 'Black', image: ConsumablesImg.MX236FT},
    {consumableName: 'MX-235FT', brand: 'Sharp', lifeYield: '19K', TonerPartName: 'AR-5618/AR-5620/AR-5623/MX-M232D MX-235FT', colour: 'Black', image: ConsumablesImg.MX235FT},
    {consumableName: 'AR-202ET', brand: 'Sharp', lifeYield: '16K', TonerPartName: 'AR-M205/AR-M206', colour: 'Black', image: ConsumablesImg.AR202ET},
    {consumableName: 'AR-021ET', brand: 'Sharp', lifeYield: '16K', TonerPartName: 'AR-5516/AR-5520', colour: 'Black', image: ConsumablesImg.AR021ET},
    {consumableName: 'AR-016ET', brand: 'Sharp', lifeYield: '16K', TonerPartName: 'AR-5616/AR-5320', colour: 'Black', image: ConsumablesImg.AR016ET},
    {consumableName: 'KYOCERA TASKalfa TK-8305 Toner Black', brand: 'KYOCERA', lifeYield: '', TonerPartName: '', colour: '', image: ConsumablesImg.AR016ET},
]
import React from "react";
import Navbar2 from "../components/Navbar2";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import CTA from "../components/CTA";

const MccCCTv = () => {
  return (
    <div>
      <Helmet>
        <title></title>
        <meta name="description" content=" " />
      </Helmet>
      <Navbar2 />

      <div className="container mt-5 mb-5">
        <h1 id="service-section " className="text-center mb-5">
          <span className="title-blue">MCC Certified </span> <br />
          <span className="title-orange">
            CCTV Solutions Provider in Abu Dhabi
          </span>
        </h1>

        <p>
          At Al Katef IT Solutions, we are proud to be an MCC-certified company
          in Abu Dhabi, specializing in cutting-edge CCTV solutions. Our
          certification reflects our commitment to the highest standards of
          safety, security, and regulatory compliance in all our operations. We
          provide advanced CCTV systems that enhance surveillance and security
          for various applications, including commercial, industrial, and
          residential settings.
        </p>

        <h5><span className="title-blue">Understanding MCC Certification</span></h5>
        <p>
          The Munitions Control Certificate (MCC) is a critical certification
          that signifies our adherence to stringent safety and compliance
          standards in managing sensitive security systems. By choosing an
          MCC-certified company, you ensure that your security solutions are
          backed by expertise and best practices in the field. Our commitment to
          maintaining these standards is reflected in every project we
          undertake.
        </p>

        <h5><span className="title-blue">Our Comprehensive CCTV Solutions</span></h5>
        <p>
        We offer a wide range of CCTV services designed to meet the specific needs of our clients, enhancing safety, security, and operational efficiency.

        </p>

        <h5><span className="title-blue">1. Customized CCTV System Design</span></h5>
        <p>
        Our experienced team works closely with you to design a CCTV system tailored to your unique requirements.<br/><br/>
        <b> Key features include : </b> <br/><br/>
        <b>Site Assessment : </b> We conduct a thorough evaluation of your premises to identify critical areas that require surveillance. This helps us determine optimal camera placements and system configurations. <br/>
        <b>System Specification : </b> Based on your needs, we recommend the best camera types (e.g., IP cameras, analog cameras) and technologies (e.g., HD, 4K) to ensure comprehensive coverage and high-quality imagery. <br/>
        <b>Integration Solutions : </b>  Our CCTV systems can be integrated with other security measures, such as access control systems and alarms, providing a cohesive security solution.
        </p>

        <h5><span className="title-blue">2. Professional Installation Services</span></h5>
        <p>Our skilled technicians ensure that your CCTV system is installed efficiently and correctly : <br/><br/>
        <b>Expert Installation : </b> For IP-based systems, we ensure robust network configuration and connectivity, optimizing bandwidth and storage solutions for high-resolution video feeds.<br/>
        <b>Network Setup : </b> Our installations adhere to local regulations and industry standards, ensuring that your CCTV system meets all necessary compliance requirements.<br/>
        <b>Compliance Assurance : </b>  Our CCTV systems can be integrated with other security measures, such as access control systems and alarms, providing a cohesive security solution.
        </p>

        <h5><span className="title-blue">3. Remote Monitoring and Access</span></h5>
        <p>In today's fast-paced environment, having remote access to your CCTV feeds is essential : <br/><br/>
        <b>Mobile and Web Access : </b> Our systems allow you to monitor live and recorded footage from any device, whether it's a smartphone, tablet, or computer, ensuring you’re always connected.<br/>
        <b>Cloud Storage Solutions : </b>We offer cloud-based storage options for easy access to your footage, providing redundancy and security against data loss.<br/>
        <b>Alert Systems : </b> Our CCTV solutions include customizable alerts that notify you of unusual activities or motion detected in sensitive areas, allowing for immediate response.
        </p>

        <h5><span className="title-blue">4. Ongoing Support and Maintenance</span></h5>
        <p>
            We believe that effective security is an ongoing process. Our support services include : <br/><br/>
        <b>24/7 Technical Support : </b> Our dedicated support team is available around the clock to assist with any technical issues or concerns, ensuring minimal disruption to your security operations.<br/>
        <b>Routine Maintenance : </b>We offer scheduled maintenance services to ensure that all components of your CCTV system are functioning optimally, including cleaning lenses, updating software, and inspecting hardware.<br/>
        <b>System Upgrades : </b> As technology evolves, we provide recommendations for system upgrades, ensuring that your CCTV setup remains state-of-the-art and effective.       
        </p>

        <h4><span className="title-orange">Why Choose Our CCTV Solutions?</span></h4>

        <p>
        <b>MCC Certification : </b> Our MCC certification validates our expertise and commitment to best practices in security management, giving you confidence in our services.<br/>
        <b>Expert Team : </b>Our team consists of experienced professionals with extensive knowledge in CCTV technology and security systems, ensuring you receive top-notch service.<br/>
        <b>Comprehensive Coverage : </b>We provide end-to-end solutions, from initial design and installation to ongoing support and maintenance, ensuring a seamless experience.<br/>    
        <b>Regulatory Compliance : </b> Our systems are designed to comply with all local laws and regulations, ensuring that your CCTV installation meets all necessary standards.
        </p>


        <CTA />
      </div>

      <section className="footer-section">
        <Footer />
      </section>
    </div>
  );
};

export default MccCCTv;

import React from "react";
import Footer from "../components/Footer";
// import NavigationBar from '../components/Navbar'
import Whatsapp from "../components/Whatsapp";
import { Helmet } from "react-helmet";
import consulting from "../images/It consulting.svg";
import Navbar2 from "../components/Navbar2";

function ItConsulting() {
  return (
    <div>
      <Whatsapp premsg="Hi there! 👋 I'm interested in learning more about your digital marketing services" />
      {/* <NavigationBar /> */}
      <Navbar2 />

      <Helmet>
        <title></title>
        <meta
          name="description"
          content="Best IT Consulting services in Abu Dhabi, we are giving business guidance in
how to use information technology to achieve their objectives and goal.We
optimize operations and drive growth.
 "
        />
      </Helmet>

      <div className="container">
        <h1 className="mt-5 text-center">
          <span className="title-blue">IT</span>
          <span className="title-orange"> Consulting</span>
        </h1>
        <div className="d-flex justify-content-center align-items-center mt-5 mb-5">
          <img src={consulting} width={"50%"} alt="Network Infrastructure" />
        </div>

        <p>
          Al Katef IT Consultancy services in Dubai help various organizations
          achieve their objectives by formulating effective plans for putting IT
          services and solutions into practice.
        </p>
        <p>
          IT consulting means providing companies with advice on how to use
          information technology to accomplish their goals. Al Katef
          organization specializes in providing excellent consulting services
          and creative solutions to maximize IT strategies and improve the
          overall performance of the organization. Al Katef is skilled in all
          work and is a reliable partner for companies looking for professional
          advice in the constantly changing field of information technology.
        </p>
        <p>
          Al Katef IT Consulting Services develops effective plans for putting
          IT Consultancy solutions and services into practice, assisting a range
          of enterprises in reaching their objectives. Having developed a team
          of skilled laborers and knowledgeable professionals who enable us to
          offer solutions in all areas of IT, we have gained skill in providing
          consulting and IT solutions & services to our esteemed clients
          globally.
        </p>
        <p>
          We provide website design, development & hosting, cloud computing,
          cyber security, business intelligence, network solutions, hardware
          support, technical help, digital marketing, and software development.
          By working privately with our clients to get to know their needs, we
          can provide workable solutions and relevant services that meet their
          needs. Our huge experience and knowledge have allowed us to provide
          small businesses, organizations, and end users with end-to-end
          assistance, hardware support, and technical support. We charge
          reasonable prices for these services.
        </p>
      </div>

      <section className="footer-section">
        <Footer />
      </section>
    </div>
  );
}

export default ItConsulting;

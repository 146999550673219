// kyocera
import TASKalfa551ci from '../images/printers/TASKalfa 5551ci.jpg';
import KYOCERATASKalfa3554C from '../images/printers/KYOCERA TASKalfa 3554C.jpg';
import KYOCERATASKalfa8353i from '../images/printers/KYOCERA TASKalfa 8353i.jpg';
import TASKalfa2551ci from '../images/printers/TASKalfa 2551ci.jpg';
import KYOCERATASKalfa7353i from '../images/printers/KYOCERA TASKalfa 7353i.jpg';
import KYOCERATASKalfa6053i from '../images/printers/KYOCERA TASKalfa 6053i.jpg';
import KYOCERATASKalfa5053ci from '../images/printers/KYOCERA TASKalfa 5053ci.jpg';
import KyoceraTaskalfa4053ci from '../images/printers/Kyocera Taskalfa 4053ci .jpg';
import KYOCERATASKalfa3253i from '../images/printers/KYOCERA TASKalfa 3253i.jpg';
import KYOCERATASKalfa2553i from '../images/printers/KYOCERA TASKalfa 2553i.jpg';
import KYOCERATASKalfa9003i from '../images/printers/KYOCERA TASKalfa 9003i.jpg';
import KYOCERATASKalfa8003i from '../images/printers/KYOCERA TASKalfa 8003i.jpg';
import KYOCERATASKalfa7003i from '../images/printers/KYOCERA TASKalfa 7003i.jpg';
import KYOCERATASKalfa6003i from '../images/printers/KYOCERA TASKalfa 6003i.jpg';
import KYOCERATASKalfa5003i from '../images/printers/KYOCERA TASKalfa 5003i.jpg';
import KYOCERATASKalfa7052ci8052ci from '../images/printers/KYOCERA TASKalfa 7052ci8052c.jpg';
import KYOCERATASKalfa4052ci5052ci6052ci from '../images/printers/KYOCERA TASKalfa 4052ci5052ci6052ci.jpg';
import KYOCERATASKalfa2552ci3252ci3552ci from '../images/printers/KYOCERA TASKalfa 2552ci3252ci3552ci.jpg';
import KYOCERAECOSYSM8124cidn from '../images/printers/KYOCERA ECOSYS M8124cidn.jpg';
import KYOCERATASKalfa9002i from '../images/printers/KYOCERA TASKalfa 9002i.jpg';
import KYOCERATASKalfa8002i from '../images/printers/KYOCERA TASKalfa 8002i.jpg';
import KYOCERATASKALFA7002i from '../images/printers/KYOCERA TASKALFA 7002i.jpg';
import KYOCERATASKalfa5002i6002i from '../images/printers/KYOCERA TASKalfa 5002i6002i.jpg';
import KYOCERATASKalfa4002i from '../images/printers/KYOCERA TASKalfa 4002i.jpg';
import KYOCERAECOSYSM4132idn from '../images/printers/KYOCERA ECOSYS M4132idn.jpg';
import KYOCERAECOSYSM4125idn from '../images/printers/KYOCERA ECOSYS M4125idn.jpg';
import KYOCERATASKalfa4012i from '../images/printers/KYOCERA TASKalfa 4012i.jpg';
import KYOCERATASKalfa3212i from '../images/printers/KYOCERA TASKalfa 3212i.jpg';
import KYOCERATASKalfa3511i from '../images/printers/KYOCERA TASKalfa 3511i.jpg'; 
import KYOCERATASKalfa3011i from '../images/printers/KYOCERA TASKalfa 3011i.jpg';
import KYOCERATASKalfa18002200 from '../images/printers/KYOCERA TASKalfa 18002200.jpg';
import KYOCERATASKalfa250ci300ci400ci500ci from '../images/printers/KYOCERA TASKalfa 250ci300ci400ci500ci.jpg';
import KYOCERATASKALFA180181220221 from '../images/printers/KYOCERA TASKALFA 180181220221.jpg';
import KYOCERAKM1635KM2035KM2050KM2035 from '../images/printers/KYOCERA KM-1635 KM-2035 KM-2050 KM-2035.jpg';
import KYOCERATASKALFA420i520i from '../images/printers/KYOCERA TASKALFA 420i520i .jpg';
import KYOCERATASKalfa620i820i from '../images/printers/KYOCERA TASKalfa 620i820i TONER TK665.jpg'; 
import KYOCERATASKalfa300i from '../images/printers/KYOCERA TASKalfa 300i.jpg';
import KyoceraKM3050KM4050KM5050 from '../images/printers/Kyocera KM-3050KM-4050 KM-5050.jpg';
import KYOCERAKM2540KM2560KM3040KM3060 from '../images/printers/KYOCERA KM-2540, KM-2560, KM-3040, KM-3060.jpg';
import KYOCERAECOSYSFS11201125MFP from '../images/printers/KYOCERA ECOSYS FS11201125MFP.jpg';
import KYOCERAECOSYSM3860idn from '../images/printers/KYOCERA ECOSYS M3860idn.jpg';
import KYOCERAECOSYSM3655idn from '../images/printers/KYOCERA ECOSYS M3655idn.jpg';
import KYOCERAFS12201320132510611041 from '../images/printers/KYOCERA FS-12201320132510611041 .jpg';
import KYOCERAECOSYSM35503560idn from '../images/printers/KYOCERA ECOSYS M35503560idn.jpg';
import KYOCERAECOSYSM30403540idn from '../images/printers/KYOCERA ECOSYS M30403540idn.jpg';
import KYOCERAECOSYSM2135dnM2635dn from '../images/printers/KYOCERA ECOSYS M2135dnM2635dn.jpg';
import KYOCERAECOSYSM20352535dn from '../images/printers/KYOCERA ECOSYS M20352535dn.jpg';
import KYOCERAECOSYSM20302530dn from '../images/printers/KYOCERA ECOSYS M20302530dn.jpg';
import KYOCERAFS35403640MFP from '../images/printers/KYOCERA FS35403640MFP.jpg';
import KYOCERAFS30403140MFP from '../images/printers/KYOCERA FS-30403140MFP.jpg';
import KYOCERAFS10351135MFP from '../images/printers/KYOCERA FS10351135MFP.jpg';
import KYOCERAECOSYSFS10301130MFP from '../images/printers/KYOCERA ECOSYS FS-10301130MFP.jpg';
import KYOCERAFS10281128MFP from '../images/printers/KYOCERA FS-10281128 MFP.jpg';
import KYOCERAFS1025MFP from '../images/printers/KYOCERA FS-1025MFP .jpg';
import KYOCERAFS10241124MFP from '../images/printers/KYOCERA FS-10241124MFP.jpg'; 
import KYOCERAFS1018MFP from '../images/printers/KYOCERA FS-1018MFP.jpg';
import KYOCERAFS1016MFP from '../images/printers/KYOCERA FS-1016MFP .jpg';
import TASKalfa3010i3510i from '../images/printers/TASKalfa 3010iTASKalfa 3510i .jpg';
import TASKalfa3501i4501i5501i from '../images/printers/TASKalfa 3501i TASKalfa 4501i TASKalfa 5501i.jpg';
import TASKalfa6501i8001i from '../images/printers/TASKalfa 6501iTASKalfa 8001i.jpg';
import TASKalfa8000i6500i from '../images/printers/TASKalfa 6500i8000i .jpg';
import TASKalfa3500i4500i5500i from '../images/printers/TASKalfa 3500i4500i5500i.jpg';
import FS6025MFPFS6030MFP from '../images/printers/FS-6025MFPFS-6030MFP.jpg';
import FSC8520MFPFSC8525MFP from '../images/printers/FS-C8520MFP FS-C8525MFP.jpg';
import FSC8020FSC8025205c255c from '../images/printers/FS-C8020 FS-C8025205c255c.jpg';
import TASKalfa6551ci7551ci from '../images/printers/TASKalfa 6551ci7551ci.jpg';
import ECOSYSM5526cdnM5526cdw from '../images/printers/ECOSYS M5526cdnM5526cdw.jpg';
import TASKalfa4551ci from '../images/printers/TASKalfa 4551ci.jpg';
import TASKalfa3551ci from '../images/printers/TASKalfa 3551ci.jpg';
import KyoceraTASKalfa3051ci from '../images/printers/Kyocera TASKalfa 3051ci.jpg';
import TASKalfa18012201 from '../images/printers/TASKalfa 18012201.jpg';
import ECOSYSM2040dnM2540dn from '../images/printers/ECOSYS M2040dnM2540dn.jpg';
import ECOSYSM3145dnM3645dn from '../images/printers/ECOSYS M3145dn  M3645dn.jpg';
import ECOSYSM5521cdnM5521cdw from '../images/printers/ECOSYS M5521cdnM5521cdw.jpg';
import ECOSYSM6230cidnM6630cidn from '../images/printers/ECOSYS M6230cidnM6630cidn.jpg';
import TASKalfa352ci from '../images/printers/TASKalfa 352ci.jpg';
import ECOSYSP5026cdn from '../images/printers/ECOSYS P5026cdn.jpg';
// Triumph-Adler
import TriumphAdler5007ci from '../images/printers/Triumph-Adler 5007ci.jpg';
import TriumphAdler4007ci from '../images/printers/Triumph-Adler 4007ci.jpg';
import TriumphAdler3207ci from '../images/printers/Triumph-Adler 3207ci.jpg';
import TriumphAdler3005ci from '../images/printers/Triumph Adler 3005ci.jpg';
import TriumphAdler2506ci from '../images/printers/Triumph-Adler 2506ci.jpg';
// Sharp
import SHARPARB351TARB353P from '../images/printers/SHARP AR-B351TAR-B353P.jpg';
import SHARPMXM364NMXM464NMXM564N from '../images/printers/SHARP MX-M364N MX-M464N MX-M564N.jpg';
import SHARPMXM354NMXM314NMXM264N from '../images/printers/SHARP MX-M354N  MX-M314N  MX-M264N.jpg';
import SHARPMXM356NVM315NVM265NVMXM356UVM315UVM265UV from '../images/printers/SHARP MX-M356NVM315NVM265NV MX-M356UVM315UVM265UV.jpg';
import SHARPAR5516AR5618AR5620AR5516AR5520 from '../images/printers/SHARP AR-5516AR-5618AR-5620AR-5516AR-5520.jpg';
import SHARPDX2500N2000U from '../images/printers/SHARP DX-2500N2000U.jpg';
import SHARPMX2310UMX3111U from '../images/printers/SHARP MX-2310UMX-3111U.jpg';
import SHARPMX2314NMX2614NMX3114N from '../images/printers/SHARP MX-2314NMX-2614NMX-3114N.jpg';
import SHARPMX1810UMX2010U from '../images/printers/SHARP MX-1810UMX-2010U.jpg';
import SHARPMX2651NMX3051NMX3551NMX4051NMX5051NMX6051N from '../images/printers/SHARP MX-2651NMX-3051NMX-3551NMX-4051NMX5051NMX-6051N.jpg'
import SHARPMX3050NMX3550NMX4050N from '../images/printers/SHARP MX-3050NMX-3550NMX-4050N.jpg';
import SHARPBP30C25 from '../images/printers/SHARP BP-30C25.jpg';
import SHARPBP20C25 from '../images/printers/SHARP BP-20C25.jpg';
import SHARPBP20C20 from '../images/printers/SHARP BP-20C20.jpg';
import MXM6050M5050M4050MXM3550M3050 from '../images/printers/MX-M6050M5050M4050 MX-M3550M3050.jpg';
import SHARPAR6031NV from '../images/printers/SHARP AR-6031NV.jpg';
import SHARPAR6026NV from '../images/printers/SHARP AR-6026NV.jpg';
import SHARPAR6023NV from '../images/printers/SHARP AR-6023NV.jpg';
import SHARPAR6020NV from '../images/printers/SHARP AR-6020NV.jpg';
// Canon
import CanonimageRUNNER2600 from '../images/printers/Canon imageRUNNER 2600.jpg';
import CanonimageRUNNERADVANCEDX4700 from '../images/printers/Canon imageRUNNER ADVANCE DX 4700.jpg';
import CanonimageRUNNERADVANCEDX6700i from '../images/printers/Canon imageRUNNER ADVANCE DX 6700i .jpg';
import imageRUNNER2425 from '../images/printers/imageRUNNER 2425.jpg';
import CanonimageRUNNERADVANCEDXC357i from '../images/printers/Canon imageRUNNER ADVANCE DX C357i .jpg';  
import imageRUNNERADVANCEDXC5800i from '../images/printers/imageRUNNER ADVANCE DX C5800i.jpg';
import imageRUNNERADVANCEDX717iZ617i from '../images/printers/imageRUNNER ADVANCE DX 717iZ  617i.jpg';
import imageRUNNERADVANCEDXC3800 from '../images/printers/imageRUNNER ADVANCE DX C3800.jpg';
import imageRUNNER1643iII1643iFII from '../images/printers/imageRUNNER 1643i II  1643iF II.jpg';
import CanonimageRUNNERC3226 from '../images/printers/Canon imageRUNNER C3226.jpg';
import CanonimageRUNNER2206N2006N2206 from '../images/printers/Canon imageRUNNER 2206N2006N2206.jpg';
import CanonimageRUNNERC3120 from '../images/printers/Canon imageRUNNER C3120.jpg';
import CanonimageRUNNERADVANCEC5500iIII from '../images/printers/Canon imageRUNNER ADVANCE C5500i III.jpg';
import CanonimageRUNNER1643i1643iF from '../images/printers/Canon imageRUNNER 1643i1643iF.jpg';
import CanonimageRUNNERADVANCEDXC3700 from '../images/printers/Canon imageRUNNER ADVANCE DX C3700.jpg';
import CanonimageRUNNERADVANCEC5535i from '../images/printers/Canon imageRUNNER ADVANCE C5535i.jpg';
import CanonRUNNERADVANCEC3530iIII from '../images/printers/Canon RUNNER ADVANCE C3530i III .jpg';
import CanonADVANCEC3520iIII from '../images/printers/Canon ADVANCE C3520i III.jpg'; 
import CanonRUNNER25202530 from '../images/printers/Canon RUNNER 25202530.jpg';
import CANONIRADVC5235524052505255 from '../images/printers/CANON IR ADV C5235524052505255.jpg';
import CanonIRADVC5030503550455051 from '../images/printers/Canon IR ADV C5030503550455051.jpg';
// Konica-Minolta
import KONICAMINOLTAbizhubC658C558C458KONICAMINOLTAbizhubC360i from '../images/printers/KONICA MINOLTA bizhub C658C558C458 KONICA MINOLTA bizhub C360i .jpg';
import KONICAMINOLTAbizhubC450i from '../images/printers/KONICA MINOLTA bizhub C450i .jpg'; 
import KONICAMINOLTAbizhubC360i from '../images/printers/KONICA MINOLTA bizhub C360i.jpg';
import KONICAMINOLTAbizhubC300i from '../images/printers/KONICA MINOLTA bizhub C300i.jpg';
import KonicaMinoltaBizhubC266 from '../images/printers/Konica Minolta Bizhub C266.jpg';
import KONICAMINOLTAbizhub458e558e658e758e from '../images/printers/KONICA MINOLTA bizhub 458e558e658e758e.jpg';
import KONICAMINOLTAbizhub367 from '../images/printers/KONICA MINOLTA bizhub 367 .jpg'; 
import KONICAMINOLTAbizhub306i266i226i from '../images/printers/KONICA MINOLTA bizhub 306i266i226i.jpg';
import KONICAMINOLTAbizhub225i205i from '../images/printers/KONICA MINOLTA bizhub 225i205i.jpg';
import KonicaMinoltabizhubC554eC454e from '../images/printers/Konica Minolta bizhub C554eC454e.jpg';
import KonicaMinoltabizhubC364eC284eC224e from '../images/printers/Konica Minolta bizhub C364eC284eC224e.jpg';
// HP
import HPColorLaserJetEnterpriseM750 from '../images/printers/HP Color LaserJet Enterprise M750.jpg';
import HPColorLaserJetProMFPM477 from '../images/printers/HP Color LaserJet Pro MFP M477.png';
// Ricoh
import RicohIMC4500 from '../images/printers/Ricoh IM C4500.jpg';
import RicohIMC3000 from '../images/printers/Ricoh IM C3000.jpg';
import RicohIMC2000 from '../images/printers/Ricoh IM C2000.jpg';
import RicohMC2000 from '../images/printers/Ricoh M C2000.jpg';
import RicohIM2702 from '../images/printers/Ricoh IM 2702.jpg';
import RicohSPC360SNW from '../images/printers/Ricoh SP C360SNW.jpg';
import RicohSP230FNW from '../images/printers/Ricoh SP 230FNW.jpg';
import RicohMPC6503SP from '../images/printers/Ricoh MP C6503SP.jpg';
import RicohMPC6004exSP from '../images/printers/Ricoh MP C6004exSP.jpg';
import RicohMPC5504exSP from '../images/printers/Ricoh MP C5504exSP.jpg';
import RicohMPC4504exSP from '../images/printers/Ricoh MP C4504exSP.jpg';
import RicohMPC307SP307SPF from '../images/printers/Ricoh MP C307SP  307SPF.jpg';
import RicohMPC3004exSP from '../images/printers/Ricoh MP C3004exSP.jpg';
import RicohMPC2011SP from '../images/printers/Ricoh MP C2011SP.jpg';
import RicohMPC2004exSP from '../images/printers/Ricoh MP C2004exSP.png';
import RicohMP501SPFMP601SPF from '../images/printers/Ricoh MP 501SPF  MP 601SPF.jpg';
import RicohMPC401SP from '../images/printers/Ricoh MP C401SP.jpg';
// UTAX
import UTAX8307ci from '../images/printers/UTAX 8307ci.jpg';
import UTAXC7307ci from '../images/printers/UTAXC 7307ci.jpg';
import UTAX6007ci from '../images/printers/UTAX 6007ci .jpg';
import UTAX5007ci from '../images/printers/UTAX 5007ci .jpg';
import UTAX4007ci from '../images/printers/UTAX 4007ci.jpg';
import UTAX3207ci from '../images/printers/UTAX 3207ci .jpg';
import UTAX2507ci from '../images/printers/UTAX 2507ci.jpg';
import UTAXPC2480i from '../images/printers/UTAX P-C2480i.jpg';
// Brother
import BrotherMFCL6900DW from '../images/printers/Brother MFCL6900DW.jpg';
import BrotherMFCL5900dw from '../images/printers/Brother MFC-L5900dw.jpg'; 
import BrotherMFCL5755DW from '../images/printers/Brother MFC-L5755DW.jpg';
// Epson
import EpsonWFC21000WFC20750WFC20600WFM21000  from '../images/printers/Epson WF-C21000  WF-C20750  WF-C20600  WF-M21000.jpg';
import EpsonProWFC878RC879RC869R from '../images/printers/Epson Pro WF-C878R  C879R  C869R.jpg';
import EpsonProWFC579R from '../images/printers/Epson Pro WF-C579R.jpg';
import EpsonProWFC5790 from '../images/printers/Epson Pro WF-C5790.jpg';
import EpsonWFC20750 from '../images/printers/Epson WF-C20750.jpg';
import EpsonWFC20600 from '../images/printers/Epson WF-C20600.jpg';


export const images = {
    // kyocera
    TASKalfa551ci: TASKalfa551ci,
    KYOCERATASKalfa3554C: KYOCERATASKalfa3554C,
    KYOCERATASKalfa8353i: KYOCERATASKalfa8353i,
    TASKalfa2551ci: TASKalfa2551ci,
    KYOCERATASKalfa7353i: KYOCERATASKalfa7353i,
    KYOCERATASKalfa6053i: KYOCERATASKalfa6053i,
    KYOCERATASKalfa5053ci: KYOCERATASKalfa5053ci,
    KyoceraTaskalfa4053ci: KyoceraTaskalfa4053ci,
    KYOCERATASKalfa3253i: KYOCERATASKalfa3253i,
    KYOCERATASKalfa2553i: KYOCERATASKalfa2553i,
    KYOCERATASKalfa9003i: KYOCERATASKalfa9003i,
    KYOCERATASKalfa8003i: KYOCERATASKalfa8003i,
    KYOCERATASKalfa7003i: KYOCERATASKalfa7003i,
    KYOCERATASKalfa6003i: KYOCERATASKalfa6003i,
    KYOCERATASKalfa5003i: KYOCERATASKalfa5003i,
    KYOCERATASKalfa7052ci8052ci: KYOCERATASKalfa7052ci8052ci,
    KYOCERATASKalfa4052ci5052ci6052ci: KYOCERATASKalfa4052ci5052ci6052ci,
    KYOCERATASKalfa2552ci3252ci3552ci: KYOCERATASKalfa2552ci3252ci3552ci,
    KYOCERAECOSYSM8124cidn: KYOCERAECOSYSM8124cidn,
    KYOCERATASKalfa9002i: KYOCERATASKalfa9002i,
    KYOCERATASKalfa8002i: KYOCERATASKalfa8002i,
    KYOCERATASKALFA7002i: KYOCERATASKALFA7002i,
    KYOCERATASKalfa5002i6002i: KYOCERATASKalfa5002i6002i,
    KYOCERATASKalfa4002i: KYOCERATASKalfa4002i,
    KYOCERAECOSYSM4132idn: KYOCERAECOSYSM4132idn,
    KYOCERAECOSYSM4125idn: KYOCERAECOSYSM4125idn,
    KYOCERATASKalfa4012i: KYOCERATASKalfa4012i,
    KYOCERATASKalfa3212i: KYOCERATASKalfa3212i,
    KYOCERATASKalfa3511i: KYOCERATASKalfa3511i,
    KYOCERATASKalfa3011i: KYOCERATASKalfa3011i,
    KYOCERATASKalfa18002200: KYOCERATASKalfa18002200,
    KYOCERATASKalfa250ci300ci400ci500ci: KYOCERATASKalfa250ci300ci400ci500ci,
    KYOCERATASKALFA180181220221: KYOCERATASKALFA180181220221,
    KYOCERAKM1635KM2035KM2050KM2035: KYOCERAKM1635KM2035KM2050KM2035,
    KYOCERATASKALFA420i520i: KYOCERATASKALFA420i520i,
    KYOCERATASKalfa620i820i: KYOCERATASKalfa620i820i,
    KYOCERATASKalfa300i: KYOCERATASKalfa300i,
    KyoceraKM3050KM4050KM5050: KyoceraKM3050KM4050KM5050,
    KYOCERAKM2540KM2560KM3040KM3060: KYOCERAKM2540KM2560KM3040KM3060,
    KYOCERAECOSYSFS11201125MFP: KYOCERAECOSYSFS11201125MFP,
    KYOCERAECOSYSM3860idn: KYOCERAECOSYSM3860idn,
    KYOCERAECOSYSM3655idn: KYOCERAECOSYSM3655idn,
    KYOCERAFS12201320132510611041: KYOCERAFS12201320132510611041,
    KYOCERAECOSYSM35503560idn: KYOCERAECOSYSM35503560idn,
    KYOCERAECOSYSM30403540idn: KYOCERAECOSYSM30403540idn,
    KYOCERAECOSYSM2135dnM2635dn: KYOCERAECOSYSM2135dnM2635dn,
    KYOCERAECOSYSM20352535dn: KYOCERAECOSYSM20352535dn,
    KYOCERAECOSYSM20302530dn: KYOCERAECOSYSM20302530dn,
    KYOCERAFS35403640MFP: KYOCERAFS35403640MFP,
    KYOCERAFS30403140MFP: KYOCERAFS30403140MFP,
    KYOCERAFS10351135MFP: KYOCERAFS10351135MFP,
    KYOCERAECOSYSFS10301130MFP: KYOCERAECOSYSFS10301130MFP,
    KYOCERAFS10281128MFP: KYOCERAFS10281128MFP,
    KYOCERAFS1025MFP: KYOCERAFS1025MFP,
    KYOCERAFS10241124MFP: KYOCERAFS10241124MFP,
    KYOCERAFS1018MFP: KYOCERAFS1018MFP,
    KYOCERAFS1016MFP: KYOCERAFS1016MFP,
    TASKalfa3010i3510i: TASKalfa3010i3510i,
    TASKalfa3501i4501i5501i: TASKalfa3501i4501i5501i,
    TASKalfa6501i8001i: TASKalfa6501i8001i,
    TASKalfa8000i6500i: TASKalfa8000i6500i,
    TASKalfa3500i4500i5500i: TASKalfa3500i4500i5500i,
    FS6025MFPFS6030MFP: FS6025MFPFS6030MFP,
    FSC8520MFPFSC8525MFP: FSC8520MFPFSC8525MFP,
    FSC8020FSC8025205c255c: FSC8020FSC8025205c255c,
    TASKalfa6551ci7551ci: TASKalfa6551ci7551ci,
    ECOSYSM5526cdnM5526cdw: ECOSYSM5526cdnM5526cdw,
    TASKalfa4551ci: TASKalfa4551ci,
    TASKalfa3551ci: TASKalfa3551ci,
    KyoceraTASKalfa3051ci: KyoceraTASKalfa3051ci,
    TASKalfa18012201: TASKalfa18012201,
    ECOSYSM2040dnM2540dn: ECOSYSM2040dnM2540dn,
    ECOSYSM3145dnM3645dn: ECOSYSM3145dnM3645dn,
    ECOSYSM5521cdnM5521cdw: ECOSYSM5521cdnM5521cdw,
    ECOSYSM6230cidnM6630cidn: ECOSYSM6230cidnM6630cidn,
    TASKalfa352ci: TASKalfa352ci,
    ECOSYSP5026cdn: ECOSYSP5026cdn,
    // Triumph-Adler
    TriumphAdler5007ci: TriumphAdler5007ci,
    TriumphAdler4007ci: TriumphAdler4007ci,
    TriumphAdler3207ci: TriumphAdler3207ci,
    TriumphAdler3005ci: TriumphAdler3005ci,
    TriumphAdler2506ci: TriumphAdler2506ci,
    // Sharp
    SHARPARB351TARB353P: SHARPARB351TARB353P,
    SHARPMXM364NMXM464NMXM564N: SHARPMXM364NMXM464NMXM564N,
    SHARPMXM354NMXM314NMXM264N: SHARPMXM354NMXM314NMXM264N,
    SHARPMXM356NVM315NVM265NVMXM356UVM315UVM265UV: SHARPMXM356NVM315NVM265NVMXM356UVM315UVM265UV,
    SHARPAR5516AR5618AR5620AR5516AR5520: SHARPAR5516AR5618AR5620AR5516AR5520,
    SHARPDX2500N2000U: SHARPDX2500N2000U,
    SHARPMX2310UMX3111U: SHARPMX2310UMX3111U,
    SHARPMX2314NMX2614NMX3114N: SHARPMX2314NMX2614NMX3114N,
    SHARPMX1810UMX2010U: SHARPMX1810UMX2010U,
    SHARPMX2651NMX3051NMX3551NMX4051NMX5051NMX6051N: SHARPMX2651NMX3051NMX3551NMX4051NMX5051NMX6051N,
    SHARPMX3050NMX3550NMX4050N: SHARPMX3050NMX3550NMX4050N,
    SHARPBP30C25: SHARPBP30C25,
    SHARPBP20C25: SHARPBP20C25,
    SHARPBP20C20: SHARPBP20C20,
    MXM6050M5050M4050MXM3550M3050: MXM6050M5050M4050MXM3550M3050,
    SHARPAR6031NV: SHARPAR6031NV,
    SHARPAR6026NV: SHARPAR6026NV,
    SHARPAR6023NV: SHARPAR6023NV,
    SHARPAR6020NV: SHARPAR6020NV,
    // Canon
    CanonimageRUNNER2600: CanonimageRUNNER2600,
    CanonimageRUNNERADVANCEDX4700: CanonimageRUNNERADVANCEDX4700,
    CanonimageRUNNERADVANCEDX6700i: CanonimageRUNNERADVANCEDX6700i,
    imageRUNNER2425: imageRUNNER2425,
    CanonimageRUNNERADVANCEDXC357i: CanonimageRUNNERADVANCEDXC357i,
    imageRUNNERADVANCEDXC5800i: imageRUNNERADVANCEDXC5800i,
    imageRUNNERADVANCEDX717iZ617i: imageRUNNERADVANCEDX717iZ617i,
    imageRUNNERADVANCEDXC3800: imageRUNNERADVANCEDXC3800,
    imageRUNNER1643iII1643iFII: imageRUNNER1643iII1643iFII,
    CanonimageRUNNERC3226: CanonimageRUNNERC3226,
    CanonimageRUNNER2206N2006N2206: CanonimageRUNNER2206N2006N2206,
    CanonimageRUNNERC3120: CanonimageRUNNERC3120,
    CanonimageRUNNERADVANCEC5500iIII: CanonimageRUNNERADVANCEC5500iIII,
    CanonimageRUNNER1643i1643iF: CanonimageRUNNER1643i1643iF,
    CanonimageRUNNERADVANCEDXC3700: CanonimageRUNNERADVANCEDXC3700,
    CanonimageRUNNERADVANCEC5535i: CanonimageRUNNERADVANCEC5535i,
    CanonRUNNERADVANCEC3530iIII: CanonRUNNERADVANCEC3530iIII,
    CanonADVANCEC3520iIII: CanonADVANCEC3520iIII,
    CanonRUNNER25202530: CanonRUNNER25202530,
    CANONIRADVC5235524052505255: CANONIRADVC5235524052505255,
    CanonIRADVC5030503550455051: CanonIRADVC5030503550455051,
    // Konica-Minolta
    KONICAMINOLTAbizhubC658C558C458KONICAMINOLTAbizhubC360i: KONICAMINOLTAbizhubC658C558C458KONICAMINOLTAbizhubC360i,
    KONICAMINOLTAbizhubC450i: KONICAMINOLTAbizhubC450i,
    KONICAMINOLTAbizhubC360i: KONICAMINOLTAbizhubC360i,
    KONICAMINOLTAbizhubC300i: KONICAMINOLTAbizhubC300i,
    KonicaMinoltaBizhubC266: KonicaMinoltaBizhubC266,
    KONICAMINOLTAbizhub458e558e658e758e: KONICAMINOLTAbizhub458e558e658e758e,
    KONICAMINOLTAbizhub367: KONICAMINOLTAbizhub367,
    KONICAMINOLTAbizhub306i266i226i: KONICAMINOLTAbizhub306i266i226i,
    KONICAMINOLTAbizhub225i205i: KONICAMINOLTAbizhub225i205i,
    KonicaMinoltabizhubC554eC454e: KonicaMinoltabizhubC554eC454e,
    KonicaMinoltabizhubC364eC284eC224e: KonicaMinoltabizhubC364eC284eC224e,
    // HP
    HPColorLaserJetEnterpriseM750: HPColorLaserJetEnterpriseM750,
    HPColorLaserJetProMFPM477: HPColorLaserJetProMFPM477,
    // Ricoh
    RicohIMC4500: RicohIMC4500,
    RicohIMC3000: RicohIMC3000,
    RicohIMC2000: RicohIMC2000,
    RicohMC2000: RicohMC2000,
    RicohIM2702: RicohIM2702,
    RicohSPC360SNW: RicohSPC360SNW,
    RicohSP230FNW: RicohSP230FNW,
    RicohMPC6503SP: RicohMPC6503SP,
    RicohMPC6004exSP: RicohMPC6004exSP,
    RicohMPC5504exSP: RicohMPC5504exSP,
    RicohMPC4504exSP: RicohMPC4504exSP,
    RicohMPC307SP307SPF: RicohMPC307SP307SPF,
    RicohMPC3004exSP: RicohMPC3004exSP,
    RicohMPC2011SP: RicohMPC2011SP,
    RicohMPC2004exSP: RicohMPC2004exSP,
    RicohMP501SPFMP601SPF: RicohMP501SPFMP601SPF,
    RicohMPC401SP: RicohMPC401SP,
    // UTAX
    UTAX8307ci: UTAX8307ci,
    UTAXC7307ci: UTAXC7307ci,
    UTAX6007ci: UTAX6007ci,
    UTAX5007ci: UTAX5007ci,
    UTAX4007ci: UTAX4007ci,
    UTAX3207ci: UTAX3207ci,
    UTAX2507ci: UTAX2507ci,
    UTAXPC2480i: UTAXPC2480i,
    // Brother
    BrotherMFCL6900DW: BrotherMFCL6900DW,
    BrotherMFCL5900dw: BrotherMFCL5900dw,
    BrotherMFCL5755DW: BrotherMFCL5755DW,
    // Epson
    EpsonWFC21000WFC20750WFC20600WFM21000: EpsonWFC21000WFC20750WFC20600WFM21000,
    EpsonProWFC878RC879RC869R: EpsonProWFC878RC879RC869R,
    EpsonProWFC579R: EpsonProWFC579R,
    EpsonProWFC5790: EpsonProWFC5790,
    EpsonWFC20750: EpsonWFC20750,
    EpsonWFC20600: EpsonWFC20600
};

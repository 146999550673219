import React from "react";
import Navbar2 from "../components/Navbar2";
import Footer from "../components/Footer";
import license from "../images/License.pdf";

const Certificate = () => {
  return (
    <>
      <Navbar2 />
      <div className="container text-center mb-5">
      <h1 className="mt-5">
          <span className="title-orange">Certificates</span>
        </h1>
      </div>
      <div style={{ paddingInline:"100px", display:"flex", justifyContent:"flex-start",alignItems:"center", gap:"40px",flexWrap:"wrap" }}>

        <iframe src={license} width="400px" height="600px"/>

      </div>
      <Footer />
    </>
  );
};

export default Certificate;

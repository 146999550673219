import React from "react";
import { Link } from "react-router-dom";
import Whatsapp from "../components/Whatsapp";
import { Helmet } from "react-helmet";
import Navbar2 from "../components/Navbar2";
import Footer from "../components/Footer";
import { marked } from "marked";

import { BounceLoader } from "react-spinners";

const Blog = ({ posts, loading }) => {
  console.log(posts);

  if (loading) {
    return (
      <div className="loading  d-flex justify-content-center align-items-center mt-5">
        <BounceLoader color={"#f15c34"} loading={loading} size={100} />
      </div>
    );
  }


  return (
    <>
      <Whatsapp />
      <Helmet>
        <title>Our Blog</title>
        <meta
          name="description"
          content="Explore insights and expert advice on digital marketing."
        />
        <link rel="canonical" href={window.location.href}></link>
      </Helmet>
      <Navbar2 />

      <div className="blog-page">
        <div className="blog-page-top">
          <h1>
            <span className="text-white">Our Blogs</span>
          </h1>
          <p className="text-white">
            Our blog offers valuable insights and expert advice on digital
            marketing and website services, keeping you informed about the
            latest trends, strategies, and industry best practices.
          </p>
          <div className="newsletter">
            <input type="email" placeholder="Your Email" />
            <button className="newsletter-btn btn">Join newsletter</button>
          </div>
        </div>

        <div className="whole-blog">
          {posts ? (
            posts.data.map((post) => {
              console.log(post?.attributes?.summary);
              const htmlContent = marked(post?.attributes?.summary);
              // Log the summary to console

              return (
                <div className="blog-details" key={post?.id}>
                  <div className="blogItem">
                    <li>
                      <Link to={`/blog/post/${post?.attributes?.blogId}`}>
                        <img
                          src={
                            post?.attributes?.coverImage?.data?.[0]?.attributes
                              ?.url || "default-image-url.jpg"
                          }
                          width="100%"
                          height={400}
                          alt={post?.attributes?.blogTitle || "Blog image"}
                        />
                      </Link>
                      <div className="blog-mainHead">
                        <p>
                          {post?.attributes?.publishedDate || "Unknown Date"}
                        </p>
                        <Link to={`/blog/post/${post?.attributes?.blogId}`}>
                          <h3>
                            {post?.attributes?.blogTitle || "Untitled Blog"}
                          </h3>
                        </Link>
                      </div>

                      <p
                        className="blog-desc mt-3"
                        dangerouslySetInnerHTML={{ __html: htmlContent }}
                      />
                    </li>
                  </div>
                  {/* <div className="publisher">
                    <img
                      src="https://static.vecteezy.com/system/resources/thumbnails/008/442/086/small_2x/illustration-of-human-icon-user-symbol-icon-modern-design-on-blank-background-free-vector.jpg"
                      alt="Publisher"
                      height="250px"
                    />
                    <p>{post?.attributes?.authorName || "Author Name"}</p>
                    <p>{post?.attributes?.profession || "Profession"}</p>
                  </div> */}
                </div>
              );
            })
          ) : (
            <p>Blogs Not Found</p>
          )}
        </div>
      </div>

      <section className="footer-section">
        <Footer />
      </section>
    </>
  );
};

export default Blog;
